<template>
  <b-container fluid>
    <b-button variant=" iq-bg-primary" size="lg" block @click="addTask()">Add Item</b-button>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col cols="12 text-right mb-4">
          </b-col>
        </b-row>
        <b-row align-v="baseline">
          <b-button size="sm" @click="changeWeek('prev')"><i class="fas fa-chevron-left"></i></b-button>
          <p  vertical-align="center" class="mr-2 ml-2">{{this.startMonth}} {{this.date.weekStart.day}} - {{this.endMonth}} {{this.date.weekEnd.day}}, {{this.weekDate.year}}</p>
          <b-button size="sm" @click="changeWeek('next')"><i class="fas fa-chevron-right"></i></b-button>
        </b-row>
        <div class="row">
          <div class="col-md-12 track">
            <iq-card id="foo" bodyClass="task-body" headerClass="task-header" class="bg-transparent shadow-none w-15" v-for="(item, index) in boards" :key="index">
              <template v-slot:headerTitle>
                <div style="background-color: white; border-radius: 7px; padding: 1px 5px 1px 5px;">
                  <p class="text-primary mb-0">
                    <i class="fas fa-chalkboard-teacher fa-xs mr-1"></i>
                    <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">2</a>
                    <i class="fas fa-exchange-alt fa-xs mr-1"></i>
                    <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">2</a>
                    <i class="fas fa-volume-up fa-xs mr-1"></i>
                    <a style="color:#9eb0e7" class="font-size-12 text-primary mr-1">2</a>
                  </p>
                </div>
                <a class="text-white font-size-18 mr-1">{{item.date}}</a>
                <a class="text-white font-size-12">{{ item.title }}</a>
              </template>
              <template v-slot:headerAction>
              </template>
              <template v-slot:body>
                <iq-card
                  v-for="(element, index) in item.list"
                  :key="element.name +'-'+ index"
                  bodyClass="task-card"
                  headerClass="task-card-title"
                >
                  <template v-slot:headerTitle>
                    <a @click="editTask(element,item)" class="card-title text-muted">{{ element.name }}</a>
                  </template>
                  <template v-slot:body>
                    <div>
                      <p class="font-size-11 mb-0">
                        <i class="fas fa-users fa-xs mr-1"></i>
                        <span class="mr-1">{{ element.users }}</span>
                        <i class="fas fa-clock fa-xs mr-1"></i>
                        <span>{{ element.hour }}</span>
                      </p>
                      <p style="min-height: 43px" class="font-size-12">
                        {{element.admin}}
                      </p>
                      <div class="d-flex justify-content-between">
                      </div>
                    </div>
                    <b-progress style="height: 4px" class="mt-2" :max="100" show-value>
                      <b-progress-bar :value="50" class="iq-border-radius-10" variant="success" label=" " />
                    </b-progress>
                  </template>
                </iq-card>
              </template>
            </iq-card>
            <b-modal id="task" centered title="New Task" hide-footer>
              <b-form @submit.prevent="saveTask(task)">
                <b-form-group label="Name">
                  <b-input v-model="task.name" />
                </b-form-group>
                <b-form-group label="Admin">
                  <b-input v-model="task.admin" />
                </b-form-group>
                <b-form-group label="Users">
                  <b-input v-model="task.users" />
                </b-form-group>
                <b-form-group>
                  <b-form-timepicker v-model="task.hour" id="hour" :state="true" class="mb-2"></b-form-timepicker>
                </b-form-group>
                <b-form-group label="Date">
                  <flat-pickr v-model="task.date" :config="dateConfig" class="form-control" />
                </b-form-group>
                <b-form-group label="Priority">
                  <b-form-radio-group
                    id="priority"
                    v-model="task.status"
                    :options="status"
                    name="priority"
                  />
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button variant=" iq-bg-primary" class="iq-waves-effect" @click="$bvModal.hide('task')">Cancel</b-button>
                  <b-button variant="primary ml-2"  class="iq-waves-effect" type="submit">Submit</b-button>
                </div>
              </b-form>
            </b-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core, flatpickerSetting } from '../../config/pluginInit'
import { db } from '@/config/firebase'
const { DateTime } = require('luxon')
// import draggable from 'vuedraggable'
export default {
  name: 'ProjectBoard',
  mounted () {
    core.index()
    this.getRowData()
    console.log(DateTime.local(2021, 11, 23).weekday)
  },
  components: {
    // draggable
  },
  data () {
    return {
      dateConfig: flatpickerSetting,
      rowDataCard: [],
      dataCard: [],
      weekDate: DateTime.now().c,
      date: {
        day: 0,
        weekStart: 0,
        weekEnd: 0,
        today: {
          day: 0,
          month: 0,
          year: 0
        }
      },
      status: [
        { text: 'Low', value: 0 },
        { text: 'High', value: 1 },
        { text: 'Critical', value: 2 }
      ],
      colors: [
        'bg-primary',
        'bg-success',
        'bg-warning',
        'bg-info',
        'bg-danger'
      ],
      board: this.defaultBoard(),
      task: this.defaultTask(),
      boards: [
        {
          id: 1,
          title: 'Lunes',
          date: '',
          color: 'bg-primary',
          list: []
        },
        {
          id: 2,
          title: 'Martes',
          date: '',
          color: 'bg-success',
          list: []
        },
        {
          id: 3,
          title: 'Miércoles',
          date: '',
          color: 'bg-info',
          list: [
          ]
        },
        {
          id: 4,
          title: 'Jueves',
          date: '',
          color: 'bg-warning',
          list: []
        },
        {
          id: 5,
          title: 'Viernes',
          date: '',
          color: 'bg-danger',
          list: []
        },
        {
          id: 6,
          title: 'Sábado',
          date: '',
          color: 'bg-danger',
          list: []
        },
        {
          id: 7,
          title: 'Domingo',
          date: '',
          color: 'bg-danger',
          list: []
        }
      ]
    }
  },
  computed: {
    startMonth () {
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      return months[this.date.weekStart.month - 1]
    },
    endMonth () {
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      return months[this.date.weekEnd.month - 1]
    }
  },
  methods: {
    async getRowData () {
      await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/prueba')
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.exists) {
                const rowData = doc.data()
                const vm = this
                for (let index2 = 0; index2 < this.boards.length; index2++) {
                  this.boards[index2].list = []
                }
                vm.rowDataCard = []
                Object.keys(rowData).forEach(function (key) {
                  vm.rowDataCard.push(rowData[key])
                })
              }
            })
            this.getWeekDates(this.weekDate)
          }
        })
    },
    async getWeekDates (myDate) {
      console.log('getting dates')
      console.log('myDate', myDate)
      this.date.today.day = DateTime.now.day
      this.date.today.month = DateTime.now.month
      this.date.today.year = DateTime.now.year
      this.date.weekStart = DateTime.local(myDate.year, myDate.month, myDate.day).startOf('week').c
      this.date.weekEnd = DateTime.local(myDate.year, myDate.month, myDate.day).endOf('week').c
      for (let index = 0; index < this.boards.length; index++) {
        this.boards[index].date = this.date.weekStart.day + index
      }
      this.getCardsInfo()
      console.log(this.date.weekStart, this.date.weekEnd)
    },
    getCardsInfo () {
      console.log('trayendo los datos', this.rowDataCard.length)
      for (let index = 0; index < this.rowDataCard.length; index++) {
        const splitedDate = this.rowDataCard[index].date.split('-')
        console.log(splitedDate)
        const year = parseFloat(splitedDate[0])
        const month = parseFloat(splitedDate[1])
        const day = parseFloat(splitedDate[2])
        const cardDay = DateTime.local(year, month, day).ordinal
        const startDate = DateTime.local(this.date.weekStart.year, this.date.weekStart.month, this.date.weekStart.day).ordinal
        const endDate = DateTime.local(this.date.weekEnd.year, this.date.weekEnd.month, this.date.weekEnd.day).ordinal
        if (cardDay >= startDate && cardDay <= endDate) {
          console.log('is between', cardDay, '>', startDate)
          for (let index2 = 0; index2 < this.boards.length; index2++) {
            if (this.boards[index2].id === this.rowDataCard[index].boardId) {
              this.boards[index2].list.push(this.rowDataCard[index])
              console.log('Si es este board', this.boards[index2].id)
            } else { console.log('No es este board', this.boards[index2].id) }
          }
        } else { console.log('is not between', cardDay, '<', startDate) }
      }
    },
    async changeWeek (type) {
      for (let index2 = 0; index2 < this.boards.length; index2++) {
        this.boards[index2].list = await []
      }
      console.log('listas vacias')
      if (type === 'next') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).plus({ days: 7 }).c
        console.log('add', this.weekDate)
        this.getWeekDates(this.weekDate)
      }
      if (type === 'prev') {
        this.weekDate = await DateTime.local(this.weekDate.year, this.weekDate.month, this.weekDate.day).minus({ days: 7 }).c
        this.getWeekDates(this.weekDate)
        console.log('minus', this.weekDate)
      }
    },
    defaultBoard () {
      return {
        id: 0,
        title: '',
        color: '',
        list: [
        ]
      }
    },
    defaultTask () {
      return {
        id: 0,
        name: '',
        description: '',
        status: 0
      }
    },
    log () {
    },
    saveBoard (item) {
      const board = this.boards.findIndex(b => b.id === item.id)
      if (board === -1) {
        item.id = this.boards.length
        item.color = this.colors[Math.floor(Math.random() * this.colors.length)]
        this.boards.push(item)
      }
      this.board = this.defaultBoard()
      this.$bvModal.hide('board')
    },
    saveTask () {
      const splitedDate = this.task.date.split('-')
      console.log(splitedDate)
      const year = parseFloat(splitedDate[0])
      const month = parseFloat(splitedDate[1])
      const day = parseFloat(splitedDate[2])
      console.log(day, typeof day)
      this.task.boardId = DateTime.local(year, month, day).weekday
      console.log(this.task.boardId)
      db.collection('/campus/Vs2FkUx38u3W8IDuABNF/prueba')
        .doc('asambleas')
        .set({
          3: this.task
        }, { merge: true })
        .then((doc) => {
          this.$bvModal.hide('task')
        })
      // for (let index = 0; index < this.boards.length; index++) {
      //   if (this.boards[index].id === this.task.boardId) {
      //     this.boards[index].list.push(this.task)
      //   }
      // }
    },
    addTask (board) {
      this.board = board
      this.task = this.defaultTask()
      this.$bvModal.show('task')
    },
    editBoard (item) {
      this.board = item
    },
    editTask (item, board) {
      this.board = board
      this.task = item
    }
  }
}
</script>
